import { useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "../styles/bannerSlider.module.css";

const ImageSlider = ({ bannerData, position, bannerType = "normal" }) => {
  const { t } = useTranslation();
  const router = useRouter();
  // console.log("path", router.pathname === "/video");

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [positionLink, setPositionLink] = useState(
    `&utm_medium=${smallScreen ? "mobile_web" : "desktop_web"}${
      position ? "&utm_source=" + position : ""
    }`
  );
  const [imageDimensions, setImageDimensions] = useState({
    width: 500,
    height: 500,
  });

  const getLink = (url) => {
    if (url.includes("?")) {
      return url + positionLink;
      // Query params exist
      // Your code here
    } else {
      return url + "?" + positionLink.slice(1);
      // Query params do not exist
      // Your code here
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 600) {
        setImageDimensions({ width: 288, height: 216 });
      } else {
        setImageDimensions({ width: 300, height: 140 });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!bannerData || bannerData.length === 0) {
    return null;
  }
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,

    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          autoplay: true,
          autoplaySpeed: 3500,
        },
      },
    ],
  };

  //console.log("imageDimensions", imageDimensions);

  return (
    <div
      className={`${styles.sliderContainer} ${router.pathname === "/home" ? styles.homeSliderPadding : null} ${
        router.pathname === "/video" ? styles.sliderPadding : null
      } ${position === "puja_receipt_banner" ? styles.pujaReceiptBanner : null}`}
    >
      <Slider {...settings}>
        {bannerData.map((banner, index) => (
          <div
            key={index}
            className={
              bannerType === "thin"
                ? styles.thin_banner_image_container
                : styles.banner_image_container
            }
          >
            <Link
              href={getLink(banner.bannerLink)}
              //href={banner.bannerLink}
              target="_blank"
              rel="noopener noreferrer alternate"
              hrefLang={router.locale}
            >
              <Image
                src={
                  !smallScreen
                    ? (banner.bannerImageWeb || banner.bannerImage) ?? ""
                    : banner.bannerImage ?? ""
                }
                alt={`${t("banner")} ${index + 1}`}
                // width={"100%"}
                // height={"100%"}
                layout="fill"
                objectFit="cover"
                className={styles.img}
                placeholder="blur"
                blurDataURL={banner.bannerImage_sm || banner.bannerImage}
                loading="eager"
                priority
              />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
